import { Box, Divider, Grid, Typography, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { ColumnCentered, Q1 } from "../models/boxes";
import hieroglifs from "../assets/hieroglif.png";
import { HieroglifBox } from "../models/customTexts";
import { AboutData, getReports } from "../axios/Api";
import { RenderOutput } from "../utils/richRenderer";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import sert1 from '../assets/sert1.jpg'
import sert2 from '../assets/sert2.jpg'
import Carousel, { Modal, ModalGateway } from "react-images";
export const AboutUs = () => {
  const isMobile = useMediaQuery("(max-width:820px)");
  const [state, setState] = useState(null);
  const [t] = useTranslation();
  const [isActive, setIsActive] = useState(false);
  const [modalImageSrc, setModalImageSrc] = useState(null);
  const [reports, setReports] = useState(null)

  const handleClick = () => {
    setIsActive(!isActive);
  };
  useEffect(() => {
    (async () => {
      const data = await AboutData();
      const reportsData = await getReports();
      setReports(reportsData)
      setState(data);
    })();
  }, []);

  const useStyles = makeStyles((theme) => ({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      gap: '1rem',
      padding: '2rem',
      textAlign: 'center',
      fontFamily: 'Montserrat',
      fontWeight: 500,
      letterSpacing: '-1px',
      color: '#464646',
      fontSize: '25px',
      overflow: 'hidden !important',
      [theme.breakpoints.down('sm')]: {
        fontSize: '20px',
        overflow: 'hidden !important',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '18px',
        overflow: 'hidden !important',
      },
    },
    item: {
      position: 'relative',
      borderRadius: '0.25rem',
      padding: '0.5rem 1rem',
      overflow: 'hidden !important',
      maxHeight: '120px',
      maxWidth: '550px',
      color: '#000',
      textAlign: 'center',
      backdropFilter: 'blur(4px)',
      cursor: 'pointer',
      transition: 'all ease-in 0.5s',
      '&.active': {
        height: 'auto',
        maxHeight: '1200px',
        cursor: 'initial',
        overflowY: 'visible',
        transition: 'all ease-out 0.5s',
        overflow: 'hidden !important',
      },
    },
  }));
  const classes = useStyles();

  return (
    <>
      {modalImageSrc && <div id="myModal" className="modal">
        <span className="close" onClick={() => setModalImageSrc(null)}>&times;</span>
        <img className="modal-content" id="img01" src={modalImageSrc} style={{ height: '80vh', objectFit: 'contain' }} />
      </div>}
      <ColumnCentered
        mt={"100px"}
        width={"100%"}
        position={"relative"}
        zIndex={20}
      >






        <HieroglifBox
          component={"img"}
          src={hieroglifs}
          padding={"0 250px"}
          width={"100%"}
          boxSizing={"border-box"}
        />
        <Typography
          mt={!isMobile ? "30px" : "0"}
          fontFamily="Noto Serif Armenian"
          fontWeight={300}
          fontSize={"60px"}
          m={"20px 0"}
          textTransform={"uppercase"}
          sx={{
            "@media (max-width:600px)": {
              fontSize: "50px",
            },
            "@media (max-width:400px)": {
              fontSize: "40px",
            },
          }}
          color={"#333333"}
        >
          {t("about")}
        </Typography>
        <div className="wrapper">
          <div className={`${classes.item} ${isActive ? 'active' : ''}`}>
            {state && <RenderOutput output={state[`textEn`]} />}
            <Grid container>
              <Grid item xs={5} justifyContent={'center'} onClick={() => setModalImageSrc(sert1)}>
                <Box component={'img'} src={sert1} sx={{ objectFit: 'contain' }} width={'100%'} height={'200px'} />
              </Grid>
              <Grid item xs={7} justifyContent={'center'} onClick={() => setModalImageSrc(sert2)}>
                <Box component={'img'} src={sert2} sx={{ objectFit: 'contain' }} width={'100%'} height={'200px'} />
              </Grid>
              <br />
              {
                reports?.map(item => (
                  <Grid Grid item xs={12} justifyContent={'center'} mt={3}>
                    <a href={item.url} target="_blank" style={{cursor:'pointer', color:'#333333', fontWeight:700,fontFamily: 'Montserrat'}}>{item[`text${i18next.language}`]}</a>
                  </Grid>
                ))
              }
            </Grid>

          </div>

        </div>
        <Q1 mt={"20px"} onClick={handleClick}>
          {!isActive ? t("readMore") : 'READ LESS'}
          <Divider
            sx={{ mt: 1, background: "#333333", border: "1px solid #464646" }}
          />
        </Q1>
      </ColumnCentered >
    </>
  );
};
