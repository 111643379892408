import { Column, ContactBox, Flex } from "../models/boxes";
import shomen from "../assets/shomen.png";
import shomenMobile from '../assets/shomenMobile.png'
import { Box } from "@mui/system";
import {
  Divider,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { ContactButton } from "../models/buttons";
import dojoImg from "../assets/dojoimg.png";
import { useEffect, useState } from "react";
import { getLocations } from "../axios/Api";
import i18next from "i18next";
import { useNavigate } from "react-router-dom";
import { ShomenBox } from "../models/imageBox";
export const ContactUs = () => {
  const [locations, setLocations] = useState(null);
  const isMobile = useMediaQuery("(max-width:850px)");
  const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      const data = await getLocations();
      setLocations(data);
    })();
  }, []);

  return (
    <ContactBox position={"relative"} mt={!isMobile ? 15 : 10}>
      <Flex
        // position={"relative"}
        // zIndex={2}
        width={"100%"}
        justifyContent={!isMobile ? "flex-end" : "center"}
        height={"100%"}
        alignItems={"center"}
      >
        {!isMobile && (
          <Box
            bottom={0}
            left={0}
            zIndex={0}
            position={"absolute"}
            sx={{
              backgroundImage: `url(${shomen})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              width: "100%",
              height: "90%",
              borderBottomLeftRadius: "80px",
            }}
          />
        )}
        <Column alignItems={"center"}>
          <Box
            component={"img"}
            src={dojoImg}
            width={250}
            height={250}
            sx={{ objectFit: "contain" }}
          />
          <Typography
            fontFamily={"Montserrat !important"}
            fontWeight={300}
            mb={5}
            maxWidth={isMobile ? "290px" : "520px"}
            textAlign={"center"}
            lineHeight={1.5}
            // fontSize={isMobile?'12px':'34px'}
            // color={"rgba(0, 0, 0, 0.5)"}
            style={{
              fontSize: isMobile ? "18px" : "20px",
              color: "rgba(0, 0, 0, 0.5)",
            
            }}
          >
            A "dojo" is a term originating from Japanese martial arts that
            refers to a dedicated training space or hall.
          </Typography>

          <Grid
            mb={3}
            container
            spacing={2}
            maxWidth={730}
            position={"relative"}
            zIndex={2}
            display={"flex"}
            justifyContent={"center"}
          >
            {locations?.map((item) => {
              return (
                <Grid
                  item
                  key={item.id}
                  xs={isMobile ? 12 : 6}
                  display={"flex"}
                  justifyContent={"center"}
                >
                  <ContactButton
                    onClick={() => navigate(`/location/${item.id}`)}
                  >
                    {item?.[`location${i18next?.language}`]&&item[`location${i18next.language||"En"}`]?.split("|")[0]}
                  </ContactButton>
                </Grid>
              );
            })}
          </Grid>
          <Column alignItems={"center"} mt={3} mb={5}>
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontWeight: 900,
                fontSize: isMobile?22:25
              }}
            >
              Contact us now!
            </Typography>
            <Flex gap={2} mt={2} mb={0.5} alignItems={"center"}>
              <Typography
                style={{
                  fontWeight: 300,
                  color: "#333333",
                  fontFamily: "Montserrat",
                  fontSize:isMobile?18:25
                }}
              >
                +374 91 42 41 89
              </Typography>
              <Divider
                orientation="vertical"
                sx={{ borderBottomWidth: 20, borderColor: "black" }}
              />
              <Typography
                style={{
                  fontWeight: 300,
                  color: "#333333",
                  fontFamily: "Montserrat",
                  fontSize:isMobile?20:25
                }}
              >
                +374 93 97 22 13
              </Typography>
            </Flex>
            <Typography
               style={{
                fontWeight: 300,
                color: "#333333",
                fontFamily: "Montserrat",
                fontSize:isMobile?20:25,
                textTransform:'lowercase'
              }}
            >aikido.aikikai@gmail.com</Typography>
          </Column>
        </Column>
      </Flex>
      {isMobile && (
        <Box position={'relative'} bottom={0}>
          <ShomenBox src={shomenMobile} />
        </Box>
        
      )}
    </ContactBox>
  );
};
