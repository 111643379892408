import { Box } from "@mui/system";
import { Column, Flex } from "../models/boxes";
import { JoinUsButton, MobileNavigationLink } from "../models/buttons";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { IconButton } from "@mui/material";

export const MobileMenu = ({ setCurrent, setOpened }) => {
  const navigate = (to) => {
    setOpened(false);
    setCurrent(to);
  };
  return (
    <Column
      boxSizing={"border-box"}
      position={"fixed"}
      width={"100%"}
      left={0}
      top={0}
      height={"100vh"}
      zIndex={1000}
      justifyContent={"center"}
      alignItems={"center"}
      sx={{
        backdropFilter: "blur(20px)",
        backgroundColor: "rgba(255, 255, 255, 0.5)",
        "*": {
          transition: "all 0.3s ease-in-out",
        },
      }}
    >
      <Box m={"20px 0"} mt={15}>
        <MobileNavigationLink href="#tab-block" onClick={() => navigate(0)}>
          Instructors
        </MobileNavigationLink>
      </Box>
      <Box m={"20px 0"}>
        <MobileNavigationLink href="#tab-block" onClick={() => navigate(1)}>
          Schedule
        </MobileNavigationLink>
      </Box>
      {/* <Box m={"20px 0"}>
        <MobileNavigationLink href="#tab-block" onClick={() => navigate(3)}>
          Places
        </MobileNavigationLink>
      </Box> */}
      <Box m={"20px 0"}>
        <MobileNavigationLink href="#tab-block" onClick={() => navigate(2)}>
          Gallery
        </MobileNavigationLink>
      </Box>
      <Box m={"20px 0"}>
        <MobileNavigationLink
          href="#seminars-block"
          onClick={() => navigate(3)}
        >
          Seminars and Events
        </MobileNavigationLink>
      </Box>
      {/* <Box mt={"14px"}>
        <JoinUsButton
          href="#contact-us"
          style={{ fontSize: "3vmax", color: "black" }}
          onClick={() => setOpened(false)}
        >
          Join us
        </JoinUsButton>
      </Box> */}
      <Flex gap={"10px"} mt={"44px"}>
        <IconButton
          onClick={() =>
            window.open(
              "https://www.facebook.com/aikidoaikikaifederationofarmenia",
              "_blank"
            )
          }
        >
          <FacebookIcon style={{ color: "rgba(0, 0, 0, 0.7)", fontSize: 50 }} />
        </IconButton>
        <IconButton
          onClick={() =>
            window.open(
              "https://www.instagram.com/aikido_aikikai_federation/",
              "_blank"
            )
          }
        >
          <InstagramIcon
            style={{ color: "rgba(0, 0, 0, 0.7)", fontSize: 50 }}
          />
        </IconButton>
        <IconButton
          onClick={() =>
            window.open("https://www.youtube.com/@ArmAikido", "_blank")
          }
        >
          <YouTubeIcon style={{ color: "rgba(0, 0, 0, 0.7)", fontSize: 50 }} />
        </IconButton>
      </Flex>
    </Column>
  );
};
