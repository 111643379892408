import {
  Breadcrumbs,
  Button,
  Divider,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Column, Flex } from "../../models/boxes";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { useEffect, useState } from "react";
import { getSensei } from "../../axios/Api";
import { RenderOutput } from "../../utils/richRenderer";
import i18next from "i18next";


export const Sensei = () => {
  const { pathname } = useLocation();
  const { id } = useParams();
  const [sensei, setSensei] = useState(null);
  const isMobile = useMediaQuery("(max-width:950px)");
  const isTablet = useMediaQuery("(max-width:1200px)");
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const data = await getSensei(id);
      setSensei(data);
    })();
  }, [id]);

  useEffect(()=>{
    window.scrollTo(0,0)
  },[])

  return (
    <>
      <Box mt={"180px"}>
        {pathname !== "/" && (
          <Divider sx={{ border: "1px solid rgba(0, 0, 0, 0.6)" }} />
        )}
      </Box>
      <Flex justifyContent={"center"} padding={isMobile ? "0 20px" : "0 80px"}>
        <Box>
          
          <Box mt={"30px"}>
            <Button
              onClick={() => navigate("/")}
              startIcon={<ArrowBackIcon />}
              color={"inherit"}
              sx={{ p: 0, fontSize: "1.44em" }}
            >
              Back to main page
            </Button>
          </Box>

          <Flex
            flexDirection={isMobile ? "column" : "row"}
            mt={"50px"}
            sx={{
              background: "white",
              p: "28px",
              borderRadius: "70px",
              zoom: isTablet ? "80%" : "100%",
            }}
          >
            <img src={sensei?.url} style={{ borderRadius: "45px",height:600 }} alt=""/>
            <Column pl={isMobile ? 0 : "50px"} mt={3}>
              <Typography
                fontFamily="Noto Serif Armenian"
                fontWeight={300}
                fontSize={isMobile ? "9vmin" : "6.5vmin"}
                lineHeight={"35px"}
                letterSpacing={"-0.04em"}
                variant="h5"
                color={"rgba(0, 0, 0, 0.8)"}
              >
                {sensei?.[`nameEn`]}
              </Typography>
              <Typography
                fontFamily="Montserrat"
                fontWeight={500}
                fontSize={isMobile ? "6vmin" : "2vmin"}
                mt={5}
                color={"rgba(0, 0, 0, 0.5)"}
              >
                {sensei?.[`positionEn`]}
              </Typography>
              <Box boxSizing={"border-box"} mt={"30px"} maxWidth={"965px"}>
                {sensei && <RenderOutput output={sensei['aboutEn']}/>}
              </Box>
              <Box width={"100%"} mt={"40px"}>
                <Button
                  startIcon={<CalendarMonthIcon />}
                  variant="contained"
                  color="warning"
                  sx={{
                    backgroundColor: "#FFB800",
                    borderRadius: "10px",
                    color: "black",
                  }}
                >
                  Schedule
                </Button>
              </Box>
            </Column>
          </Flex>
        </Box>
      </Flex>
    </>
  );
};
