export const InstructorsIcon = ({ color = "white" }) => {
  return (
    <svg
    //   width="5.23vmin"
    //   height="3.76vmin"
      viewBox="0 0 45 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.9997 16.5C28.0722 16.5 26.2084 15.6394 24.7497 14.0775C23.3312 12.5541 22.465 10.5225 22.3122 8.35874C22.1491 6.05062 22.8531 3.92812 24.2941 2.38125C25.735 0.834374 27.7497 0 29.9997 0C32.2337 0 34.2541 0.849374 35.6903 2.3925C37.1406 3.95062 37.8465 6.06937 37.6834 8.3578C37.5269 10.5244 36.6615 12.555 35.2459 14.0765C33.7909 15.6394 31.9281 16.5 29.9997 16.5Z"
        fill={color}
      />
      <path
        d="M42.3595 33H17.6423C17.2449 33.0021 16.8523 32.913 16.4947 32.7396C16.1372 32.5662 15.8242 32.313 15.5798 31.9996C15.3206 31.6599 15.1416 31.266 15.0562 30.8472C14.9708 30.4285 14.9812 29.9959 15.0867 29.5818C15.876 26.4122 17.8317 23.7834 20.7417 21.9806C23.3245 20.3812 26.6123 19.5 30.0004 19.5C33.4551 19.5 36.6567 20.3437 39.2545 21.9422C42.171 23.7356 44.1295 26.3793 44.9151 29.5875C45.0193 30.0019 45.0285 30.4345 44.9422 30.853C44.8558 31.2714 44.6761 31.665 44.4163 32.0043C44.1722 32.3163 43.8599 32.5682 43.5034 32.7407C43.1469 32.9133 42.7556 33.002 42.3595 33Z"
        fill={color}
      />
      <path
        d="M12.2809 16.875C8.9818 16.875 6.08118 13.8075 5.81212 10.0378C5.67899 8.10653 6.28087 6.32059 7.49962 5.01091C8.70524 3.71434 10.4059 2.99997 12.2809 2.99997C14.1559 2.99997 15.8434 3.71809 17.0555 5.02216C18.2837 6.34215 18.8837 8.12434 18.743 10.0397C18.474 13.8084 15.5743 16.875 12.2809 16.875Z"
        fill={color}
      />
      <path
        d="M18.4368 19.8234C16.7877 19.0172 14.6474 18.614 12.2821 18.614C9.5202 18.614 6.83801 19.334 4.72864 20.6409C2.33708 22.125 0.72833 24.2859 0.0786433 26.895C-0.0164331 27.2703 -0.0254256 27.6622 0.0523384 28.0414C0.130102 28.4207 0.29261 28.7774 0.527705 29.085C0.750781 29.3713 1.03657 29.6027 1.36308 29.7614C1.68959 29.92 2.04814 30.0016 2.41114 30H12.8174C12.993 29.9999 13.1631 29.9383 13.2979 29.8257C13.4327 29.7132 13.5238 29.5568 13.5552 29.384C13.5655 29.325 13.5786 29.2659 13.5936 29.2078C14.3886 26.0147 16.2514 23.3165 19.0039 21.3497C19.1052 21.2767 19.1866 21.1796 19.2408 21.0672C19.2951 20.9548 19.3204 20.8307 19.3145 20.706C19.3087 20.5813 19.2718 20.4601 19.2073 20.3533C19.1427 20.2465 19.0525 20.1575 18.9449 20.0944C18.7977 20.0081 18.6289 19.9172 18.4368 19.8234Z"
        fill={color}
      />
    </svg>
  );
};
