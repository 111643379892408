import {
  Breadcrumbs,
  Button,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useEffect, useState } from "react";
import { getlocation } from "../../axios/Api";
import i18next from "i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";

export const Location = () => {
  const { pathname } = useLocation();
  const { id } = useParams();
  const [location, setLocation] = useState(null);
  const isMobile = useMediaQuery("(max-width:950px)");
  const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      const data = await getlocation(id);
      setLocation(data);
    })();
  }, [id]);

  useEffect(()=>{
    window.scrollTo(0,0)
  },[])

  return (
    <>
      <Box mt={"180px"}>
        {pathname !== "/" && (
          <Divider sx={{ border: "1px solid rgba(0, 0, 0, 0.6)" }} />
        )}
      </Box>
      <Box padding={isMobile ? "0 20px" : "0 80px"}>
        <Box>
          <Breadcrumbs
            sx={{ mt: "30px" }}
            separator={<NavigateNextIcon fontSize="medium" />}
            aria-label="breadcrumb"
          >
            <Typography color="#333333" letterSpacing={0.9}>
              {location?.[`location${i18next.language||"En"}`]}
            </Typography>
          </Breadcrumbs>
          <Box mt={"30px"}>
            <Button
              onClick={() => navigate("/")}
              startIcon={<ArrowBackIcon />}
              color={"inherit"}
              sx={{ p: 0, fontSize: "1.44em" }}
            >
              Back to main page
            </Button>
          </Box>

          <Box mt={"50px"} width={"100%"}>
            {location && (
              <iframe
                src={`https://maps.google.com/maps?q=${location.map_url}&z=15&output=embed`}
                width="100%"
                height="100%"
                style={{ border: 0, borderRadius: "50px", height: "70vh" }}
                title="map"
              />
            )}
          </Box>
          {!isMobile ? (<Grid container spacing={"50px"} mt={"50px"}>
            {location?.media?.map((item) => {
              return (
                <Grid item xs={4}>
                  <img
                    src={item?.media?.url}
                    width={"100%"}
                    height={"600px"}
                    style={{ objectFit: "cover", borderRadius: 30 }}
                    alt=""
                  />
                </Grid>
              );
            })}
          </Grid>):<Swiper
              centeredSlidesBounds={true}
              spaceBetween={30}
              // slidesPerView={seminarSliderSlideCount()}
              pagination={{ clickable: true }}
              modules={[Pagination]}
              breakpoints={{
                // 320: {
                //   slidesPerView: 1,
                // },
                320: {
                  slidesPerView: 1,
                },
                650:{
                    slidesPerView:2
                },
                1000:{
                    slidesPerView:3
                },
                // 425:{
                //     slidesPerView:1.1
                // },
                // 470:{
                //     slidesPerView:1.3
                // },
                // 570:{
                //     slidesPerView:1.5
                // },
                // 700: {
                //   slidesPerView: 1.8,
                // },
                // 761: {
                //   slidesPerView: 2.2,
                // },
                // 820: {
                //   slidesPerView: 1.9,
                // },
                // 900: {
                //   slidesPerView: 2.1,
                // },
                // 1024: {
                //   slidesPerView: 2,
                // },
                // 1130: {
                //   slidesPerView: 2.2,
                // },
              }}
            >
              {location?.media?.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <img
                    src={item?.media?.url}
                    width={"100%"}
                    height={"600px"}
                    style={{ objectFit: "cover", borderRadius: 30 }}
                    alt=""
                  />
                  </SwiperSlide>
                );
              })}
            </Swiper>}
        </Box>
      </Box>
    </>
  );
};
