import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import am from "./translations/am";
import en from "./translations/en";
import ru from "./translations/ru";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: !localStorage.i18nextLng||localStorage.i18nextLng==='en'?"En":localStorage.i18nextLng,
    resources: {
      En: {
        translations: en,
      },
      Am: {
        translations: am,
      },
      Ru: {
        translations: ru,
      },
    },
    fallbackLng: "En",
    debug: true,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys
  });

export default i18n;
