import {
  Box,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";

import {
  AlignCenter,
  Column,
  Flex,
  LogoBox,
  SpaceBetween,
} from "../models/boxes";
import { CustomNavigationBtn, JoinUsButton } from "../models/buttons";
import { ContactText } from "../models/customTexts";
import LanguageMenu from "./languageMenu";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { FooterLogo } from "../assets/icons/footerLogo";
import { useEffect, useState } from "react";
import { getLocations } from "../axios/Api";
import i18next from "i18next";

export const Footer = ({setCurrent}) => {
  const isNoutbook = useMediaQuery("(max-width:1495px)");
  const isNoutbook2 = useMediaQuery("(max-width:1350px)");
  const isNoutbook3 = useMediaQuery("(max-width:1210px)");
  const isTablet = useMediaQuery("(max-width:1065px)");
  const isMobile = useMediaQuery("(max-width:700px)");
  const [locations, setLocations] = useState(null);

  useEffect(() => {
    (async () => {
      const data = await getLocations();
      setLocations(data);
    })();
  }, []);

  const footerSizes = () => {
    if (isNoutbook2 && !isNoutbook3) {
      return "80%";
    }

    if (isNoutbook && isNoutbook2 && isNoutbook3) {
      return "70%";
    } else {
      return "90%";
    }
  };

  const navigate = (to) => {
    setCurrent(to);
    window.location.href = '#tab-block'
  };

  return (
    <>
      {!isTablet ? (
        <Box
          boxSizing={"border-box"}
          position={"relative"}
          zIndex={999}
          p={"90px 60px"}
          mt={25}
          sx={{
            background: "#333333",
            borderTopLeftRadius: 80,
            borderTopRightRadius: 80,
            zoom: footerSizes(),
          }}
        >
          <Flex justifyContent={"space-between"} width={"100%"}>
            <Flex width={"100%"}>
              <Column>
                <Typography
                  fontFamily={"Noto Serif Armenian"}
                  fontWeight={500}
                  fontSize={"3.2vw"}
                  textTransform={"uppercase"}
                  color={"white"}
                  width={"430px"}
                  lineHeight={1.3}
                  letterSpacing={"0.01em"}
                >
                  start the journey of
                  <br /> a lifetime
                </Typography>
          
              </Column>

              <Column gap={"30px"} alignItems={"flex-start"}>
                <CustomNavigationBtn style={{ fontWeight: 600 }} onClick={()=>navigate(0)}>
                  Instructors
                </CustomNavigationBtn>
                <CustomNavigationBtn style={{ fontWeight: 600 }} onClick={()=>navigate(1)}>
                  Schedule
                </CustomNavigationBtn>
                {/* <CustomNavigationBtn style={{ fontWeight: 600 }}>
                  Places
                </CustomNavigationBtn> */}
                <CustomNavigationBtn style={{ fontWeight: 600 }} onClick={()=>navigate(2)}>
                  Gallery
                </CustomNavigationBtn>
                <CustomNavigationBtn style={{ fontWeight: 600 }} onClick={()=>window.location.href = '#seminars-block'}>
                  Seminars and Events
                </CustomNavigationBtn>
              </Column>

              <Column width={"100%"}>
                <Flex justifyContent={"flex-end"}>
                  <Typography
                    fontFamily={"Noto Serif Armenian"}
                    fontWeight={400}
                    fontSize={"5vmin"}
                    textTransform={"uppercase"}
                    color={"white"}
                    lineHeight={0}
                  >
                    contacts
                  </Typography>
                </Flex>
                <Flex width={"100%"} mt={"60px"} justifyContent={"flex-end"}>
                  <Column width={"340px"}>
                    <CustomNavigationBtn
                      style={{
                        textAlign: "right",
                        fontWeight: 600,
                        fontSize: "2.8vmin",
                      }}
                    >
                      E-mail:
                    </CustomNavigationBtn>
                    <ContactText
                      style={{ fontSize: "1.4vmax", fontWeight: 300 }}
                    >
                      aikido.aikikai@gmail.com
                    </ContactText>
                  </Column>
                  <Column width={"400px"}>
                    <CustomNavigationBtn
                      style={{
                        textAlign: "right",
                        fontWeight: 600,
                        fontSize: "2.8vmin",
                      }}
                    >
                      Phone:
                    </CustomNavigationBtn>
                    <CustomNavigationBtn
                      style={{
                        textAlign: "right",
                        fontSize: "1.4vmax",
                        fontWeight: 300,
                      }}
                    >
                      +374 93 972213
                    </CustomNavigationBtn>
                    <CustomNavigationBtn
                      style={{
                        textAlign: "right",
                        fontSize: "1.4vmax",
                        fontWeight: 300,
                      }}
                    >
                      +374 91 424189
                    </CustomNavigationBtn>
                  </Column>
                </Flex>
                <Flex width={"100%"} justifyContent={"flex-end"} mt={"40px"}>
                  <Column width={"250px"}>
                    <CustomNavigationBtn
                      style={{
                        textAlign: "right",
                        fontWeight: 600,
                        fontSize: "2.8vmin",
                      }}
                    >
                      Addresses:
                    </CustomNavigationBtn>
                    {
                      locations?.map(item => {
                        return (
                          <ContactText
                            style={{ fontSize: "1.4vmax", fontWeight: 300 }}
                          >
                            {item[`location${i18next.language || "En"}`]}
                          </ContactText>
                        )
                      })
                    }

                  </Column>
                  <Column width={"400px"}>
                    <CustomNavigationBtn
                      style={{
                        textAlign: "right",
                        fontWeight: 600,
                        fontSize: "2.8",
                      }}
                    >
                      Working Hours:
                    </CustomNavigationBtn>
                    <ContactText
                      style={{ fontSize: "1.4vmax", fontWeight: 200 }}
                    >
                      MON-FRI | 16:00-21:30
                    </ContactText>
                    <ContactText
                      style={{ fontSize: "1.4vmax", fontWeight: 200 }}
                    >
                      SAT | 9:00-17:00
                    </ContactText>
                  </Column>
                </Flex>
              </Column>
            </Flex>
          </Flex>
          <AlignCenter width={"100%"} justifyContent={"space-between"} mt={9}>
            <AlignCenter>
              <LogoBox>
                <FooterLogo />
              </LogoBox>
            </AlignCenter>
            <AlignCenter gap={2} width={400}>
              <IconButton
                onClick={() =>
                  window.open(
                    "https://www.facebook.com/aikidoaikikaifederationofarmenia",
                    "_blank"
                  )
                }
              >
                <FacebookIcon fontSize="large" style={{ color: "white" }} />
              </IconButton>
              <IconButton
                onClick={() =>
                  window.open(
                    "https://www.instagram.com/aikido_aikikai_federation/",
                    "_blank"
                  )
                }
              >
                <InstagramIcon fontSize="large" style={{ color: "white" }} />
              </IconButton>
              <IconButton
                onClick={() =>
                  window.open("https://www.youtube.com/@ArmAikido", "_blank")
                }
              >
                <YouTubeIcon fontSize="large" style={{ color: "white" }} />
              </IconButton>
            </AlignCenter>
            <Column alignItems={"flex-end"} mr={3}>
              <Typography color={"white"} fontSize={"1.3vmax"}>
                © 2023 AAFA. All Rights Reserved
              </Typography>
              <Typography color={"white"} fontSize={"1.3vmax"}>
                This website was created by Aikidokas:{" "}

                <a
                  style={{ color: "white" }}
                  href="https://www.linkedin.com/in/armine-petrosyan-160146216/"
                  target={"_blank"}
                >
                  Armine
                </a>
                ,{" "}
                <a
                  style={{ color: "white" }}
                  href="https://www.linkedin.com/in/diana-yeghikyan-968103182/"
                  target={"_blank"}
                >
                  Diana
                </a>,{" "}
                <a
                  style={{ color: "white" }}
                  href="https://www.linkedin.com/in/vahan-muradyan-1833331b7/"
                  target={"_blank"}
                >
                  Vahan
                </a>
              </Typography>
            </Column>
          </AlignCenter>
        </Box>
      ) : (
        <Box
          boxSizing={"border-box"}
          p={"20px"}
          mt={10}
          position={"relative"}
          zIndex={999}
          sx={{
            background: "#333333",
            borderTopLeftRadius: 40,
            borderTopRightRadius: 40,
            zoom: isMobile ? "80%" : "100%",
          }}
        >
          <SpaceBetween width={"100%"}>
            <Typography
              fontFamily={"Noto Serif Armenian"}
              fontWeight={500}
              fontSize={"10.5vmin"}
              textTransform={"uppercase"}
              color={"white"}
              // width={'200px'}
              lineHeight={1.3}
              letterSpacing={"0.01em"}
            >
              start the journey of
              <br /> a lifetime
            </Typography>
            {/* <Box>
              <JoinUsButton
                style={{
                  fontSize: "1.8vmax",
                  fontWeight: "bolder",
                  whiteSpace: "nowrap",
                  color: "black",
                }}
              >
                join us
              </JoinUsButton>
            </Box> */}
          </SpaceBetween>
          <Grid container mt={4}>
            <Grid item xs={6}>
              <Grid container>
                <Grid item xs={12}>
                  <CustomNavigationBtn
                    style={{
                      fontWeight: 600,
                      fontSize: "2.3vmax",
                      textAlign: "left",
                    }}
                  >
                    Phone:
                  </CustomNavigationBtn>
                </Grid>
                <Grid item xs={12}>
                  <CustomNavigationBtn
                    style={{
                      fontWeight: 300,
                      fontSize: "2vmax",
                      textAlign: "left",
                    }}
                  >
                    +374 93 972213
                  </CustomNavigationBtn>
                </Grid>
                <Grid item xs={12}>
                  <CustomNavigationBtn
                    style={{
                      fontWeight: 300,
                      fontSize: "2vmax",
                      textAlign: "left",
                    }}
                  >
                    +374 91 424189
                  </CustomNavigationBtn>
                </Grid>
              </Grid>

              <Grid container mt={"22px"}>
                <Grid item xs={12}>
                  <CustomNavigationBtn
                    style={{
                      fontWeight: 600,
                      fontSize: "2.3vmax",
                      textAlign: "left",
                    }}
                  >
                    Adresses:
                  </CustomNavigationBtn>
                </Grid>
                {
                  locations?.map(item => {
                    return (
                      <Grid item xs={12}>
                        <CustomNavigationBtn
                          style={{
                            fontWeight: 300,
                            fontSize: "2vmax",
                            textAlign: "left",
                          }}
                        >
                          {item[`location${i18next.language || "En"}`]}
                        </CustomNavigationBtn>
                      </Grid>
                    )
                  })
                }

              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container>
                <Grid item xs={12}>
                  <CustomNavigationBtn
                    style={{
                      fontWeight: 600,
                      fontSize: "2.3vmax",
                      textAlign: "left",
                    }}
                  >
                    Working Hours:
                  </CustomNavigationBtn>
                </Grid>
                <Grid item xs={12}>
                  <CustomNavigationBtn
                    style={{
                      fontWeight: 300,
                      fontSize: "2vmax",
                      textAlign: "left",
                    }}
                  >
                    MON-FRI | 16:00-21:30
                  </CustomNavigationBtn>
                </Grid>
                <Grid item xs={12}>
                  <CustomNavigationBtn
                    style={{
                      fontWeight: 300,
                      fontSize: "2vmax",
                      textAlign: "left",
                    }}
                  >
                    SAT | 9:00-17:00
                  </CustomNavigationBtn>
                </Grid>
              </Grid>

              <Grid container mt={"22px"}>
                <Grid item xs={12}>
                  <CustomNavigationBtn
                    style={{
                      fontWeight: 600,
                      fontSize: "2.3vmax",
                      textAlign: "left",
                    }}
                  >
                    E-mail:
                  </CustomNavigationBtn>
                </Grid>
                <Grid item xs={12}>
                  <CustomNavigationBtn
                    style={{
                      fontWeight: 300,
                      fontSize: "2vmax",
                      textAlign: "left",
                    }}
                  >
                    aikido.aikikai@gmail.com
                  </CustomNavigationBtn>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Flex justifyContent={"center"} mt={"45px"}>
            <Box>
              <FooterLogo />
            </Box>
            {/* <img src={FooterLogo} width={'70%'} style={{objectFit:'contain'}}/> */}
          </Flex>
          <Column alignItems={"center"} mt={3} width={"100%"}>
            <Typography color={"white"} fontSize={"1.8vmax"}>
              © 2023 AAFA. All Rights Reserved
            </Typography>
            <Typography
              mt={2}
              color={"white"}
              fontSize={"1.8vmax"}
              textAlign={"center"}
            >
              This website was created by Aikidokas:
              <br />{" "}
              <a
                style={{ color: "white" }}
                href="https://www.linkedin.com/in/vahan-muradyan-1833331b7/"
                target={"_blank"}
              >
                Vahan
              </a>
              ,{" "}
              <a
                style={{ color: "white" }}
                href="https://www.linkedin.com/in/armine-petrosyan-160146216/"
                target={"_blank"}
              >
                Armine
              </a>
              ,{" "}
              <a
                style={{ color: "white" }}
                href="https://www.linkedin.com/search/results/all/?fetchDeterministicClustersOnly=false&heroEntityKey=urn%3Ali%3Afsd_profile%3AACoAACsWBaABAtOeY6u0ZYd9_mTCEH8sshqYgZM&keywords=diana%20yeghikyan&origin=RICH_QUERY_SUGGESTION&position=0&searchId=1e71928b-8e54-446e-8ec8-113e60d1ba1c&sid=UpK"
                target={"_blank"}
              >
                Diana
              </a>
            </Typography>
          </Column>
        </Box>
      )}
    </>
  );
};
