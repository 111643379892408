import { useEffect, useState } from "react";
import { AlignCenter, LogoBox, SpaceBetween } from "../models/boxes";
import { CustomButton } from "../models/buttons";
import LanguageMenu from "./languageMenu";
import {
  IconButton,
  useMediaQuery,
} from "@mui/material";
import logo from "../assets/logo.svg";
import MenuIcon from "@mui/icons-material/Menu";
import mobileLogo from "../assets/mobileLogo.png";
import { MobileMenu } from "./mobileMenu";
import CloseIcon from "@mui/icons-material/Close";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Footer } from "./footer";
import { useWindowPosition } from "../hooks/trackScrollBar";

export const Header = ({ setCurrent }) => {
  const [opened, setOpened] = useState(false);
  const isMobile = useMediaQuery("(max-width:820px)");
  const { pathname } = useLocation();
  const scrolldata = useWindowPosition();
  const navigate = useNavigate()
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
  const [isVisible, setIsVisible] = useState(true);


  
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const isScrollingDown = currentScrollPos > prevScrollPos;

      setIsVisible(isScrollingDown ? false : true);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  return (
    <>
      {opened && <MobileMenu setCurrent={setCurrent} setOpened={setOpened} />}
      <SpaceBetween
        padding={isMobile ? "0 20px" : "0 80px"}
        boxSizing={"border-box"}
        sx={{
          opacity: isVisible ? 1 : 0,
          transition: "opacity 0.3s ease-in-out",

        }}
        alignItems={"center"}
        position={!isMobile ? (isVisible ? "fixed" : "absolute") : "fixed"}
        top={!isMobile ? 66 : (!isVisible ? -100 : 20)}
        width={"100%"}
        zIndex={9999}
      >
        {!isMobile && (
          <LogoBox
            // pl={7}
            onClick={() => navigate('/')}
          >
            <img src={logo} alt="logo" />
          </LogoBox>
        )}
        {!isMobile ? (
          <AlignCenter
            // pr={10}
            gap={7}
          >
            {/* <LanguageMenu color={pathname !== "/" ? "black" : "white"} /> */}
            {/* <CustomButton
              href="#contact-us"
              color={pathname !== "/" ? "black" : "white"}
            >
              Join us
            </CustomButton> */}
          </AlignCenter>
        ) : (
          <>
            <SpaceBetween
              pt={scrolldata > 10 ? 10 : 15}
              pb={"25px"}
              gap={"10px"}
              pr={"20px"}
              position={"fixed"}
              left={0}
              width={"100%"}
              sx={
                scrolldata > 10
                  ? {
                    backdropFilter: "blur(20px)",
                    backgroundColor: "rgba(255, 255, 255, 0.5)",
                    height: "50px",
                  }
                  : {}
              }
            >
              <AlignCenter pl={"20px"} onClick={() => navigate('/')}>
                <img
                  src={mobileLogo}
                  alt="logo"
                  width={"90px"}
                  height={"90px"}
                  style={{ objectFit: "contain" }}
                />
              </AlignCenter>
              <AlignCenter pr={3}>
                {/* <LanguageMenu color={"black"} /> */}
                <IconButton onClick={() => setOpened(!opened)}>
                  {opened ? (
                    <CloseIcon fontSize="large" />
                  ) : (
                    <MenuIcon fontSize="large" />
                  )}
                </IconButton>
              </AlignCenter>
            </SpaceBetween>
          </>
        )}
      </SpaceBetween>

      <Outlet />
      <Footer setCurrent={setCurrent}/>
    </>
  );
};
