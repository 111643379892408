import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";

export const StyledNavLink = styled(NavLink)(() => ({
  textDecoration: "none",
  color: "#443636",
  fontWeight: 600,
  fontSize: "larger",
  "&.active": {
    color: "#377a6d",
  },
}));

export const CustomButton = styled("a")(({ color = "white" }) => ({
  border: `2px solid ${color}`,
  textDecoration: "none",
  borderRadius: "32.5px",
  width: "166px",
  height: "65px",
  background: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color,
  fontFamily: "Montserrat",
  fontWeight: 600,
  fontSize: "22px",
  textAlign: "center",
  textTransform: "uppercase",
  cursor: "pointer",
  transition: "0.3s",
  "&:hover": {
    background: "white",
    color: "rgba(0, 0, 0, 0.6)",
    transition: "0.3s",
  },
  "@media (max-width: 1600px)": {
    width: 138,
    height: 54,
    fontSize: 18,
  },
  "@media (max-width: 1536px)": {
    width: 132,
    height: 52,
    fontSize: 17,
  },

  "@media (max-width: 1440px)": {
    width: 124,
    height: 48,
    fontSize: 16,
  },

  "@media (max-width: 1366px)": {
    width: 118,
    height: 46,
    fontSize: 15,
  },

  "@media (max-width: 1280px)": {
    width: 110,
    height: 43,
    fontSize: 14,
  },
}));

export const ContactButton = styled("button")(() => ({
  width: "350px",
  height: 80,
  background:'none',
  borderRadius: 50,
  cursor: "pointer",
  fontFamily: "Montserrat",
  fontStyle: "normal",
  border: '2px solid #333333',
  fontWeight: 400,
  fontSize: 25,
  textTransform: "capitalize",
  color: "#333333",
  transition:'all 0.3s',
  ":hover":{
    background:'#FFB800',
    color:'white',
    border: '2px solid #FFB800',
    transition:'all 0.3s',
  }
}));

export const JoinUsButton = styled("a")(() => ({
  padding: "19px 37px",
  border: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textDecoration: "none",
  background: "#FFB800",
  fontFamily: "Montserrat",
  fontWeight: 600,
  borderRadius: 32,
  fontSize: "1.1vw",
  cursor: "pointer",
  textTransform: "uppercase",
}));

export const CustomNavigationBtn = styled("button")(() => ({
  color: "white",
  border: "none",
  background: "none",
  fontSize: "2.8vmin",
  fontFamily: "Montserrat",
  fontWeight: 500,
  cursor: "pointer",
  whiteSpace: "nowrap",
}));

export const MobileNavigationLink = styled("a")(() => ({
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: 800,
  fontSize: "calc(16px + 150 * ((100vw - 320px) / 680))",
  lineHeight: "20px",
  textAlign: "center",
  letterSpacing: "-0.02em",
  color: "#333333",
  textDecoration: "none",
}));
