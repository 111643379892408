import { Divider, Grid, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import { Column, Q1, ScheduleCard, SpaceBetween } from "../models/boxes";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Grid as GridSlide } from "swiper";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
import { useEffect, useState } from "react";
import { getSchedules } from "../axios/Api";

export const ScheduleBlock = () => {
  const isMobile = useMediaQuery("(max-width:820px)");
  const [schedule, setSchedule] = useState(null);

  useEffect(() => {
    (async () => {
      const data = await getSchedules();
      setSchedule(data);
    })();
  }, []);
  return (
    <>
      {!isMobile ? (
        <Grid container spacing={"40px"} mt={"10px"}>
          {schedule?.map((item) => {
            return (
              <Grid item xs={6} key={item.id}>
                <ScheduleCard>
                  <SpaceBetween width={"100%"}>
                    <Column
                      p={"2.188vmax"}
                      width={"230px"}
                      justifyContent={"space-between"}
                    >
                      <Box>
                        <Typography
                          fontFamily={"Noto Serif Armenian"}
                          fontWeight={300}
                          fontSize={"5.75vmax"}
                          variant="h4"
                          lineHeight={0.8}
                        >
                          {item.year}
                        </Typography>
                        <Typography
                          fontFamily={"Montserrat"}
                          fontWeight={500}
                          fontSize={"1.72vmax"}
                          paragraph
                        >
                          years old
                        </Typography>
                      </Box>
                      <Box>
                        <Q1>
                          Read more
                          <Divider
                            sx={{
                              mt: 1,
                              background: "rgba(0, 0, 0, 0.8)",
                              border: "1px solid #464646",
                            }}
                          />
                        </Q1>
                      </Box>
                    </Column>
                    <Box width={"100%"}>
                      <img
                        alt=""
                        src={item.url}
                        style={{
                          objectFit: "cover",
                          width: "100%",
                          height: "100%",
                          borderBottomRightRadius: 30,
                          borderTopRightRadius: 30,
                        }}
                      />
                    </Box>
                  </SpaceBetween>
                </ScheduleCard>
              </Grid>
            );
          })}
        </Grid>
      ) : (
        <Box mt={'50px'}>
          <Swiper
            centeredSlidesBounds={true}
            style={{ paddingBottom: '35px' }}
            grid={{
              rows: 2,
              fill: "row",
            }}
            spaceBetween={30}
            breakpoints={{
              320: {
                slidesPerView: 1,
                grid: {
                  rows: 2,
                  fill: "row",
                },
              },
              425: {
                slidesPerView: 1.1,
                grid: {
                  rows: 2,
                  fill: "row",
                },
              },
              470: {
                slidesPerView: 1.3,
                grid: {
                  rows: 2,
                  fill: "row",
                },
              },
              570: {
                slidesPerView: 1.5,
                grid: {
                  rows: 2,
                  fill: "row",
                },
              },
              700: {
                slidesPerView: 1.8,
                grid: {
                  rows: 2,
                  fill: "row",
                },
              },
              761: {
                slidesPerView: 2.2,
                grid: {
                  rows: 2,
                  fill: "row",
                },
              },
              820: {
                slidesPerView: 2.1,
                grid: {
                  rows: 2,
                  fill: "row",
                },
              },
              900: {
                slidesPerView: 2.3,
                grid: {
                  rows: 2,
                  fill: "row",
                },
              },
              1024: {
                slidesPerView: 2,
                grid: {
                  rows: 2,
                  fill: "row",
                },
              },
              1130: {
                slidesPerView: 2.2,
                grid: {
                  rows: 2,
                  fill: "row",
                },
              },
            }}
            pagination={{
              clickable: true,
            }}
            modules={[GridSlide, Pagination]}
          >
            {schedule?.map((item) => {
              return (
                <SwiperSlide key={item.id}>
                  <ScheduleCard>
                    <SpaceBetween width={"100%"}>
                      <Column
                        p={"2.188vmax"}
                        width={"230px"}
                        justifyContent={"space-between"}
                      >
                        <Box>
                          <Typography
                            fontFamily={"Noto Serif Armenian"}
                            fontWeight={300}
                            fontSize={"5.75vmax"}
                            variant="h4"
                            lineHeight={0.8}
                          >
                            5-8
                          </Typography>
                          <Typography
                            fontFamily={"Montserrat"}
                            fontWeight={500}
                            fontSize={"1.72vmax"}
                            paragraph
                          >
                            years old
                          </Typography>
                        </Box>
                        <Box>
                          <Q1>
                            Read more
                            <Divider
                              sx={{
                                mt: 1,
                                background: "rgba(0, 0, 0, 0.8)",
                                border: "1px solid #464646",
                              }}
                            />
                          </Q1>
                        </Box>
                      </Column>
                      <Box width={"100%"}>
                        <img
                          alt=""
                          src={item.url}
                          style={{
                            objectFit: "cover",
                            width: "100%",
                            height: "100%",
                            borderBottomRightRadius: 30,
                            borderTopRightRadius: 30,
                          }}
                        />
                      </Box>
                    </SpaceBetween>
                  </ScheduleCard>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </Box>
      )}
    </>
  );
};
