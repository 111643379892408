import { useEffect, useState } from "react";
import { getSeminars } from "../../axios/Api";
import {
  Column,
  EventCard,
  EventCardAddress,
  EventCardContent,
  EventCardDate,
  EventCardDescription,
  EventCardTitle,
  Flex,
} from "../../models/boxes";
import { useNavigate } from "react-router-dom";
import i18next from "i18next";
import { LocationIcon } from "../../assets/icons/locationIcon";
import { Grid } from "@mui/material";
import { PaddingLayout } from "../../layouts/PaddingLayout";

const Seminars = () => {
  const [seminars, setSeminars] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const data = await getSeminars();
      setSeminars(data);
    })();
  }, []);

  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <PaddingLayout>
      <Grid spacing={"40px"} container mt={20}>
        {seminars?.map((item) => {
          return (
            <Grid item xs={3} key={item.id}>
              <EventCard onClick={() => navigate(`/seminar/${item.id}`)}>
                {/* {item.isActive&&
                                            <Box position={'absolute'} top={'-2px'} right={'-2px'} zIndex={999} width={200}>
                                                <UpcomingIcon/>
                                            </Box>
                                            } */}
                <img
                  src={item?.media?.[0]?.media?.url}
                  alt="img"
                  style={{ objectFit: "cover !important" }}
                />
                <EventCardContent gap={2}>
                  <Column gap={1}>
                    <EventCardTitle>
                      {item[`subTitle${i18next.language || "En"}`]}
                    </EventCardTitle>
                  </Column>
                </EventCardContent>
              </EventCard>
            </Grid>
          );
        })}
      </Grid>
    </PaddingLayout>
  );
};

export default Seminars;
