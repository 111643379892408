export const GalleryIcon = ({ color = "white" }) => {
  return (
    <svg
    //   width="4.08vmin"
    //   height="4.08vmin"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_198_335)">
        <path
          d="M0.00230939 17.4988C0.00230939 13.3962 -0.00302656 9.29277 0.00459622 5.19014C0.00916989 2.84426 1.51772 0.842866 3.76568 0.21562C4.23372 0.0852933 4.73377 0.0113652 5.2201 0.0106031C13.4123 -0.00159123 21.6045 -0.00311552 29.7975 0.00450593C32.7018 0.00679237 34.9909 2.29856 34.9939 5.20996C35.0023 13.4023 35.0015 21.5953 34.9939 29.7876C34.9916 32.6975 32.704 34.9908 29.7982 34.9938C21.6053 35.0022 13.4131 35.0015 5.2201 34.9946C2.28914 34.9923 0.00916989 32.7028 0.00383394 29.7686C-0.00378884 25.6781 0.00230939 21.5885 0.00230939 17.4988ZM2.81512 22.7675C4.77646 20.5901 6.6974 18.4568 8.60995 16.3335C9.6962 15.1278 11.5562 15.0348 12.7567 16.1269L20.3551 23.035C20.9772 23.6005 21.9209 23.6211 22.5665 23.083C23.6207 22.205 24.6704 21.3316 25.7216 20.4567C26.6272 19.7029 27.9421 19.7022 28.8484 20.4559C29.9492 21.3713 31.0529 22.2889 32.1834 23.2286C32.1834 23.0167 32.1834 22.9032 32.1834 22.7904C32.1834 17.1741 32.1842 11.5579 32.1826 5.94162C32.1819 4.07512 30.9234 2.81682 29.0581 2.81606C21.3629 2.8153 13.6669 2.81454 5.97171 2.81606C4.0561 2.81606 2.81588 4.06369 2.81588 5.98735C2.81435 11.426 2.81512 16.8654 2.81512 22.3041C2.81512 22.4169 2.81512 22.5305 2.81512 22.7675Z"
          fill={color}
        />
        <path
          d="M24.5106 7.00007C26.4492 7.01205 28.0178 8.59491 27.9998 10.5209C27.9824 12.4638 26.4056 14.0189 24.4724 13.9998C22.5371 13.9808 20.9957 12.4251 21 10.497C21.0049 8.55896 22.5812 6.98809 24.5106 7.00007Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_198_335">
          <rect width="35" height="35" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
};
