import { AlignCenter } from "../models/boxes";
import s1 from "../assets/sponsors/image10.png";
import s2 from "../assets/sponsors/image11.png";
import s3 from "../assets/sponsors/image12.png";
import s4 from "../assets/sponsors/image13.png";
import s5 from "../assets/sponsors/image14.png";
import s6 from "../assets/sponsors/image15.png";
import s7 from "../assets/sponsors/image9.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { useMediaQuery } from "@mui/material";
const images = [
  <AlignCenter
    justifyContent={"center"}
    maxWidth={380}
    minWidth={100}
    height={150}
    mr={"50px"}
  >
    <img src={s1} width="100%" height="100%" style={{ objectFit: "contain" }} alt="" />
  </AlignCenter>,
  <AlignCenter
    justifyContent={"center"}
    maxWidth={250}
    minWidth={100}
    height={150}
    mr={"75px"}
  >
    <img src={s2} width="100%" height="100%" style={{ objectFit: "contain" }} alt="" />
  </AlignCenter>,
  <AlignCenter
    justifyContent={"center"}
    maxWidth={250}
    minWidth={100}
    height={150}
    mr={"75px"}
  >
    <img src={s7} width="100%" height="100%" style={{ objectFit: "contain" }} alt="" />
  </AlignCenter>,
  <AlignCenter
    justifyContent={"center"}
    maxWidth={280}
    minWidth={100}
    height={150}
    mr={"40px"}
  >
    <img src={s3} width="100%" height="100%" style={{ objectFit: "contain" }} alt="" />
  </AlignCenter>,
  <AlignCenter
    justifyContent={"center"}
    maxWidth={420}
    minWidth={100}
    height={150}
    mr={"40px"}
  >
    <img src={s4} width="100%" height="100%" style={{ objectFit: "contain" }} alt="" />
  </AlignCenter>,
  <AlignCenter
    justifyContent={"center"}
    maxWidth={300}
    minWidth={100}
    height={150}
    mr={"75px"}
  >
    <img src={s5} width="100%" height="100%" style={{ objectFit: "contain" }} alt="" />
  </AlignCenter>,
  <AlignCenter
    justifyContent={"center"}
    maxWidth={300}
    minWidth={100}
    height={150}
    mr={"20px"}
  >
    <img src={s6} width="100%" height="100%" style={{ objectFit: "contain" }} alt="" />
  </AlignCenter>,
];
export const Sponsors = () => {
  const isMobile = useMediaQuery("(max-width:820px)");
  return (
    <Swiper
      slidesPerView={isMobile ? 3 : 4}
      centeredSlidesBounds={true}
      spaceBetween={30}
      // centeredSlides={true}
      autoplay={{
        delay: 2000,
        disableOnInteraction: false,
        // reverseDirection:true
      }}
      loop={true}
      modules={[Autoplay, Pagination, Navigation]}
    >
      {images.map((item, index) => {
        return <SwiperSlide key={index}>{item}</SwiperSlide>;
      })}
    </Swiper>
  );
};
