import styled from "@emotion/styled";

export const ShomenBox = styled('img')(({
    width:"100%", 
    objectFit:'contain',
    // objectPosition:'10px 120px',
    scale: '1.1',
    position:'relative',
    "@media (max-width:500px)":{
        bottom:'-8px'
    }
}))

// mt={"20px"} position={"relative"} bottom={'-20px'} height={'55vh'}