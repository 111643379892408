import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";

export const ContactText = styled(Typography)(() => ({
  fontFamily: "Montserrat",
  fontWeight: 400,
  fontSize: "4vmin",
  color: "white",
  textAlign: "right",
}));

export const SeminarTitleText = styled(Typography)(() => ({
  fontFamily: "Noto Serif Armenian",
  fontWeight: 300,
  fontSize: 50,
  letterSpacing: "-0.05em",
  color: "rgba(0, 0, 0, 0.8)",
  lineHeight: 1.1,
  // '@media (max-width:1420px)':{
  //     fontSize: 35,
  // },
  // '@media (max-width:1266px)':{
  //     fontSize: 30,
  // },
}));

export const MainText = styled(Typography)(() => ({
  fontFamily: "Noto Serif Armenian",
  fontWeight: 300,
  fontSize: "100px",
  letterSpacing: "-0.05em",
  textTransform: "uppercase",
  lineHeight: 1,
  maxWidth: 1200,
  color: "rgba(0, 0, 0, 0.8)",
  "@media (max-width:1920px)": {
    fontSize: "120px",
    maxWidth: 1300,
  },
  "@media (max-width:1500px)": {
    fontSize: "100px",
  },
  "@media (max-width:1300px)": {
    fontSize: 80,
    maxWidth: 700,
  },
  "@media (max-width:390px)": {
    fontSize: 60,
  },
  // '@media (max-width:1024px)':{
  //     fontSize:'80px',
  //     maxWidth:850
  // },
  // '@media (max-width:800px)':{
  //     fontSize:'70px',
  // },
  // '@media (max-width:500px)':{
  //     fontSize:'60px',
  //     maxWidth:490
  // },
}));

export const HieroglifBox = styled(Box)(() => ({
  '@media (min-width:1200px)':{
      
        width:800,
      padding: 0
  },
  '@media (max-width:1200px)':{
      padding: "0 200px"
  },
  '@media (max-width:1160px)':{
    padding: "0 150px"
  },

  '@media (max-width:820px)':{
    padding: "0 60px"
  },
  '@media (max-width:480px)':{
    padding: "0 20px"
  },
  // '@media (max-width:500px)':{
  //     width:'100vw'
  // },
}));
