import {
  Box,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import video from "../assets/video.mp4";
import {
  Column,
  SpaceBetween,
} from "../models/boxes";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { MainText } from "../models/customTexts";
export const VideoSection = () => {
  const isMobile = useMediaQuery("(max-width:820px)");

  return (
    <Box position={"relative"} width={"100%"} height={"calc(100vh - 4px)"}>
      <video
        style={{
          borderRadius: isMobile ? "0 0 70px 70px" : "0 0 80px 80px",
          touchAction: "pan-y",
          objectFit: "cover",
        }}
        autoPlay
        loop
        muted
        className="video"
        playsInline
        width={"100%"}
        height={"100%"}
      >
        <source src={'https://admin.aikikai.am/media/video.mp4'} type="video/mp4" style={{ touchAction: "pan-y" }} />
      </video>
      <SpaceBetween
        padding={isMobile ? "0 20px" : "0 80px"}
        boxSizing={"border-box"}
        position={"absolute"}
        bottom={40}
        alignItems={"flex-end"}
      >
        {!isMobile&&<MainText
          // p={!isMobile?'0 0 0 96px':'0 30px'}
          pb={"16px"}
        >
          "Aikido: The Way of Harmony & Balance"
        </MainText>}
        {!isMobile && (
          <Column
            // pr={9}
            justifyContent={"flex-end"}
            position={"relative"}
            zIndex={999}
            right={'-8px'}
          >
            <IconButton
              p={0}
              onClick={() =>
                window.open(
                  "https://www.facebook.com/aikidoaikikaifederationofarmenia",
                  "_blank"
                )
              }
            >
              <FacebookIcon
                style={{ color: "rgba(0, 0, 0, 0.7)", fontSize: "40px" }}
              />
            </IconButton>
            <IconButton
              p={0}
              onClick={() =>
                window.open(
                  "https://www.instagram.com/aikido_aikikai_federation/",
                  "_blank"
                )
              }
            >
              <InstagramIcon
                style={{ color: "rgba(0, 0, 0, 0.7)", fontSize: "40px" }}
              />
            </IconButton>
            <IconButton
              p={0}
              onClick={() =>
                window.open("https://www.youtube.com/@ArmAikido", "_blank")
              }
            >
              <YouTubeIcon
                style={{ color: "rgba(0, 0, 0, 0.7)", fontSize: "40px" }}
              />
            </IconButton>
          </Column>
        )}
      </SpaceBetween>
    </Box>
  );
};
