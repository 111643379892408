import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";

export const Flex = styled(Box)(() => ({
  display: "flex",
}));

export const SpaceBetween = styled(Flex)(() => ({
  justifyContent: "space-between",
  width: "100%",
}));

export const AlignCenter = styled(Flex)(() => ({
  alignItems: "center",
}));

export const Column = styled(Flex)(() => ({
  flexDirection: "column",
}));

export const ColumnYCenter = styled(Column)(() => ({
  justifyContent: "center",
}));

export const ColumnXCenter = styled(Column)(() => ({
  alignItems: "center",
}));

export const ColumnCentered = styled(ColumnYCenter)(() => ({
  alignItems: "center",
}));

export const TransitionResizable = styled(Box)(({ isResized = false }) => ({
  maxWidth: isResized ? "800px" : "660px",
  transition: "0.5s",
}));

export const StyledCarouselItem = styled(Flex)({
  width: 460,
  height: 280,
  background: "#FFFFFF",
  boxShadow: "25px 25px 50px rgba(0, 0, 0, 0.2)",
  borderRadius: 50,
});

export const Group201 = styled("div")({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  width: "760px",
  height: `480px`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  margin: "20px !important",
  marginBottom: "100px !important",
});

export const Rectangle7 = styled("div")({
  backgroundColor: `rgba(255, 255, 255, 1)`,
  boxShadow: `25px 25px 50px rgba(0, 0, 0, 0.2)`,
  borderRadius: `50px`,
  width: `760px`,
  height: `480px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

export const Q = styled("div")({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  color: `rgba(0, 0, 0, 0.8)`,
  fontStyle: `normal`,
  fontFamily: `Montserrat arm`,
  fontWeight: `500`,
  fontSize: `31.49751853942871px`,
  letterSpacing: `-0.6299503707885742px`,
  textDecoration: `none`,
  // lineHeight: `37.47861099243164px`,
  textTransform: `none`,
  width: `255.92px`,
  height: `88.42px`,
  position: `absolute`,
  left: `62px`,
  top: `129px`,
});

export const Q58 = styled("div")({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  color: `rgba(0, 0, 0, 0.8)`,
  fontStyle: `normal`,
  fontFamily: `Montserrat arm`,
  fontWeight: `300`,
  fontSize: `95.45059967041016px`,
  letterSpacing: `-3.818023986816406px`,
  textDecoration: `none`,
  // lineHeight: `122.1986312866211px`,
  textTransform: `none`,
  width: `154.74px`,
  height: `120.47px`,
  position: `absolute`,
  left: `56px`,
  top: `23px`,
});

export const Group16 = styled("div")({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `272.92px`,
  height: `54.41px`,
  left: `62px`,
  top: `385px`,
});

export const TabBox = styled(Flex)(({ active }) => ({
  padding: "24px 56px",
  background: active ? "rgba(0, 0, 0, 0.8)" : "white",
  borderRadius: 48,
  width: 330,
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  "&:hover": {
    background: active ? "rgba(0, 0, 0, 0.8)" : "#D7D7D7",
  },
  "@media (max-width: 1440px)": {
    width: 200,
    padding: "14px 36px",
  },
}));

export const TrainerCard = styled(Column)({
  // maxWidth: 377,
 
  background: "#FFFFFF",
  // boxShadow: '0px 4px 40px 5px rgba(0, 0, 0, 0.25)',
  borderRadius: "30px",
  "& h5": {
    "@media (max-width: 1500px)": {
      //  fontSize:26
    },
  },
  "@media (max-width: 1600px)": {
    // width:300
  },
  "@media (max-width: 1536px)": {
    // width:284
  },

  "@media (max-width: 1440px)": {
    // width:260
  },

  "@media (max-width: 1366px)": {
    // width:241
  },

  "@media (max-width: 1326px)": {
    // width:231
  },

  "@media (max-width: 1024px)": {
    // width: "500px",
  },
  "@media (max-width: 820px)": {
    // maxWidth: "100%",
    //  width:'calc(100% - 55px)',
  },

  "&>img": {
    height: 493,
    // width:'100%',
    "@media (max-width: 1600px)": {
      height: 350,
    },
    "@media (max-width: 1366px)": {
      height: 355,
    },

    "@media (max-width: 1280px)": {
      height: 250,
    },
    "@media (max-width: 1024px)": {
      height: "70vh",
    },
  },
});

export const EventsBox = styled(Box)({
  // width:'calc(100% - 180px)',
  position: "relative",
  zIndex: 999,
  // '@media (max-width: 1600px)':{
  //   width:'calc(100% - 210px)',
  // },
  // '@media (max-width: 1536px)':{
  //   width:'calc(100% - 200px)',
  // },

  // '@media (max-width: 1440px)':{
  //   width:'calc(100% - 190px)',
  // },

  // '@media (max-width: 1366px)':{
  //   width:'calc(100% - 180px)',
  // },

  // '@media (max-width: 1326px)':{
  //   width:'calc(100% - 170px)',
  // },

  // '@media (max-width: 1280px)':{
  //   width:'calc(100% - 160px)',
  // },
  "@media (max-width: 820px)": {
    boxSizing: "border-box",
    // padding:'20px'
  },
});

export const GradientLeft = styled(Box)({
  background:
    "linear-gradient(90deg, rgba(243, 239, 234, 0) 16.22%, #F3EFEA 91.35%)",
  backgroundBlendMode: "lighten",
  mixBlendMode: "normal",
  transform: "matrix(-1, 0, 0, 1, 0, 0)",
  position: "absolute",
  left: 0,
  height: "100%",
  width: 200,
  zIndex: 999,
  top: 0,
});

export const GradientRight = styled(Box)({
  background:
    "linear-gradient(90deg, rgba(243, 239, 234, 0) 16.22%, #F3EFEA 91.35%)",
  backgroundBlendMode: "lighten",
  mixBlendMode: "normal",
  transform: "matrix(1, 0, 0, -1, 0, 0)",
  position: "absolute",
  right: 0,
  height: "100%",
  width: 200,
  zIndex: 999,
  top: 0,
});

export const Q1 = styled("button")(({ mt = "50px" }) => ({
  background: "none",
  border: "none",
  marginTop: mt,
  // borderBottom:'2px solid rgba(0, 0, 0, 0.8)',
  cursor: "pointer",
  whiteSpace: `pre-wrap`,
  color: `#333333`,
  fontStyle: `normal`,
  fontFamily: `Montserrat`,
  fontWeight: `600`,
  fontSize: `20px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  // lineHeight: `52.716575622558594px`,
  textTransform: `uppercase`,
}));

export const Line1 = styled("div")({
  border: `1px solid rgba(0, 0, 0, 0.8)`,
  width: `255.92px`,
  height: `0px`,
  position: `absolute`,
  left: `0px`,
  top: `54px`,
});

export const Q191 = styled("img")({
  height: `480px`,
  width: `380px`,
  objectFit: `cover`,
  position: `absolute`,
  left: `380px`,
  top: `0px`,
});

export const ScheduleCard = styled(Flex)(() => ({
  // maxWidth: 808,
  background: "#FFFFFF",
  // boxShadow: '0px 0px 40px 5px rgba(0, 0, 0, 0.25)',
  borderRadius: 30,
  // '@media (max-width:1600px)':{
  //   width: 673,
  //   height: 411,
  //   borderRadius: 25,
  // },

  // '@media (max-width: 1536px)':{
  //   width: 646,
  //   height: 395,
  // },

  // '@media (max-width: 1440px)':{
  //   width: 606,
  //   height: 370,
  // },

  // '@media (max-width: 1366px)':{
  //   // width: 574,
  //   // height: 351,
  //   borderRadius:21
  // },

  // '@media (max-width: 1326px)':{
  //   width: 554,
  //   height: 331,
  // },

  // '@media (max-width: 1280px)':{
  //   width: 538,
  //   height: 329,
  // },
}));

export const EventCard = styled(Column)(() => ({
  // width: 377,
  background: "#FFFFFF",
  position: "relative",
  boxSizing: "border-box",
  borderRadius: 30,
  cursor: "pointer",
  justifyContent:'space-between',
  "& img": {
    // width: "100%",
    height: 283,
    borderTopLeftRadius:30,
    borderTopRightRadius:30,
    objectFit:'cover',
    "@media (max-width:1600px)": {
      height: 235,
    },

    "@media (max-width: 1536px)": {
      height: 226,
    },

    "@media (max-width: 1440px)": {
      height: 212,
    },

    "@media (max-width: 1366px)": {
      height: 201,
    },

    "@media (max-width: 1326px)": {
      height: 198,
    },

    "@media (max-width: 1280px)": {
      height: 189,
    },
  },

  // '@media (max-width:1600px)':{
  //   width: 314,
  // },

  // '@media (max-width: 1536px)':{
  //   width: 308,
  //   borderRadius:24
  // },

  // '@media (max-width: 1440px)':{
  //   width: 283,
  // },

  // '@media (max-width: 1366px)':{
  //   width: 269
  // },

  // '@media (max-width: 1326px)':{
  //   width: 255
  // },

  // '@media (max-width: 1280px)':{
  //   width: 251
  // },

  "@media (max-width: 800px)": {
    // width: 377,
    "& img": {
      height: 300,
      objectFit: "cover",
      borderRadius: 30,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
}));

export const EventCardContent = styled(Column)(() => ({
  padding: "23px 32px",
  height:'100%',
  justifyContent:'space-between',
  "@media (max-width:1600px)": {
    padding: "20px 27px",
  },

  "@media (max-width: 1536px)": {
    padding: "18px 26px",
  },

  "@media (max-width: 1440px)": {
    padding: "17px 24px",
  },

  "@media (max-width: 1366px)": {
    padding: "16px 22px",
  },

  "@media (max-width: 1280px)": {
    padding: "15px 30px",
    paddingBottom: "30px",
  },
}));

export const EventCardTitle = styled(Typography)(() => ({
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: 600,
  
  lineHeight: 1.3,
  letterSpacing: "-0.02em",
  color: "#333333",
  
  "@media (min-width:1537px)": {
    fontSize: '36px !important',
    // height:80
    // lineHeight: 31
    // marginBottom:50
  },

  "@media (max-width: 1536px)": {
    fontSize: 24,
    // lineHeight: 37
  },

  "@media (max-width: 1440px)": {
    fontSize: 20,
    // lineHeight: 30
  },

  "@media (max-width: 1366px)": {
    // fontSize: 26,
    // lineHeight: 28
  },

  "@media (max-width: 1280px)": {
    // fontSize: 24,
    // lineHeight: 27
  },
    "@media (max-width: 820px)": {
      fontSize: 28,
      lineHeight:1.2,
      // marginBottom:20
      height:'unset !important'
    // lineHeight: 27

  },
}));

export const EventCardDescription = styled(Typography)(() => ({
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: 16,
  // marginTop:10,
  // lineHeight: 25,
  letterSpacing: "-0.02em",
  color: "#464646",

  "@media (max-width:1600px)": {
    fontSize: 13,
    // lineHeight: 21
  },

  "@media (max-width: 1536px)": {
    fontSize: 12,
    // lineHeight: 20
  },

  "@media (max-width: 1440px)": {
    // lineHeight: 19
  },

  "@media (max-width: 1366px)": {
    fontSize: 11,
    // lineHeight: 18
  },

  "@media (max-width: 1280px)": {
    fontSize: 10,
    // lineHeight: 17
  },
  "@media (max-width: 820px)": {
    fontSize: 16,
    // lineHeight: 17
  },
}));

export const EventCardDate = styled(ColumnCentered)(({ active = false }) => ({
  width: 199,
  height: 58,
  background: "#FFD159",
  borderRadius: 20,
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: 23,
  // lineHeight: 56,
  color: "#464646",
  "@media (max-width:1600px)": {
    width: 166,
    height: 48,
    fontSize: 19,
    // lineHeight: 46,
  },

  "@media (max-width: 1536px)": {
    width: 159,
    height: 46,
    fontSize: 18,
    // lineHeight: 44,
  },

  "@media (max-width: 1440px)": {
    width: 149,
    height: 43,
    fontSize: 17,
    // lineHeight: 42,
  },

  "@media (max-width: 1366px)": {
    width: 141,
    height: 41,
    fontSize: 16,
    // lineHeight: 40,
  },

  "@media (max-width: 1280px)": {
    width: 180,
    height: 50,
    fontSize: 20,
    // lineHeight: 37,
  },
}));

export const EventCardAddress = styled(Typography)(() => ({
  display: "flex",
  alignItems: "center",
  gap: 5,
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "1vmax",
  lineBreak: "none",
  // lineHeight: 37,
  letterSpacing: "-0.02em",
  color: "#464646",
  marginTop:'15px',
  "@media (max-width:820px)":{
    fontSize: 16
  }
}));

export const SectionTitle = styled(Typography)(() => ({
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: 700,
  
  letterSpacing: "-0.02em",
  color: "#464646",
  "@media (max-width:820px)": {
    fontSize: "30px",
  },
   
}));

export const SeeAllButton = styled("button")(() => ({
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: 30,
  textAlign: "center",
  textTransform: "uppercase",
  color: "rgba(3, 3, 3, 0.8)",
  background: "none",
  paddingBottom: 10,
  border: "none",
  borderBottom: "3px solid rgba(0, 0, 0, 0.8)",
  cursor: "pointer",
}));

export const ContactBox = styled(Box)(() => ({
  width: "100%",
  height:'900px',
  // height: '1034px',
  padding: "100px",
  boxSizing: "border-box",
  background: "rgba(255, 255, 255, 0.5)",
  boxShadow: "0px 0px 40px 5px rgba(0, 0, 0, 0.1)",
  borderRadius: "80px",
  "& h4": {
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: 66,
    letterSpacing: "-0.04em",
    // textTransform: "uppercase",
    color: "rgba(0, 0, 0, 0.7)",
  },
  // zoom:'95%'
  "@media (max-width: 1700px)": {
    zoom: "95%",
  },
  "@media (max-width: 1600px)": {
    zoom: "90%",
  },

  "@media (max-width: 1530px)": {
    zoom: "84%",
  },

  "@media (max-width: 1440px)": {
    zoom: "79%",
  },

  "@media (max-width: 1360px)": {
    zoom: "75%",
  },

  "@media (max-width: 1300px)": {
    zoom: "70%",
  },

  "@media (max-width: 1230px)": {
    zoom: "66%",
  },

  "@media (max-width: 1160px)": {
    zoom: "60%",
  },

  "@media (max-width: 1070px)": {
    zoom: "55%",
  },

  "@media (max-width: 1000px)": {
    zoom: "50%",
  },
  "@media (max-width: 930px)": {
    zoom: "45%",
    borderRadius:'60px'
  },

  "@media (max-width: 850px)": {
    height: "auto",
    zoom: 1,
    borderRadius: "60px",
    padding: "20px",
    "& p": {
      fontFamily: "'Noto Serif Armenian'",
      fontStyle: "normal",
      fontWeight: 300,
      fontSize: 34,
      letterSpacing: "-0.04em",
      // textTransform: "uppercase",
      color: "rgba(0, 0, 0, 0.7)",
      zoom: "100%",
    },
  },
  "@media (max-width: 780px)": {
    zoom: "90%",
  },

  "@media (max-width: 700px)": {
    zoom: "85%",
  },

  "@media (max-width: 670px)": {
    zoom: "80%",
  },
}));

export const LogoBox = styled(Box)(() => ({
  width: 350,
  height: 55,
  "& img": {
    height: "100%",
  },
}));

export const FooterBlock = styled({});
