import {
  Box,
  Breadcrumbs,
  Button,
  Divider,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AlignCenter, Column, Flex } from "../../models/boxes";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ShareIcon } from "../../assets/icons/shareIcon";
import mainImg from "../../assets/main.png";
import { SeminarTitleText } from "../../models/customTexts";
import { useEffect, useState } from "react";
import { getSeminar } from "../../axios/Api";
import i18next from "i18next";
import { RenderOutput } from "../../utils/richRenderer";
import SkiderSeminar from "../../components/slider-seminar";
const Seminar = () => {
  const isLaptop = useMediaQuery("(max-width:1512px)");
  const isMobile = useMediaQuery("(max-width:960px)");
  const { pathname } = useLocation();
  const { id } = useParams();
  const [seminar, setSeminar] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      const data = await getSeminar(id);
      setSeminar(data);
    })();
  }, [id]);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Box mt={"150px"}>
        {pathname !== "/" && (
          <Divider sx={{ border: "1px solid rgba(0, 0, 0, 0.1)" }} />
        )}
      </Box>
      <Flex
        justifyContent={"center"}
        padding={isMobile ? "0 20px" : "0 80px"}
        sx={{ zoom: isLaptop ? "80%" : "100%" }}
 
        >
        <Box>
          <Breadcrumbs
            sx={{ mt: "30px" }}
            separator={<NavigateNextIcon fontSize="medium" />}
            aria-label="breadcrumb"
          >
            <Typography color="#333333" letterSpacing={0.9}>
              Seminars and Events
            </Typography>
          </Breadcrumbs>
          <Box mt={"30px"}>
            <Button
              onClick={() => navigate("/")}
              startIcon={<ArrowBackIcon />}
              color={"inherit"}
              sx={{ p: 0, fontSize: "1.44em" }}
            >
              Back to main page
            </Button>
          </Box>
          <Flex mt={"40px"} gap={6} flexDirection={isMobile ? "column" : "row"}>
            <Box wdth={470} boxSizing={"border-box"}>
              <Column
                sx={{ background: "white", p: "38px", borderRadius: "70px" }}
              >
                <SeminarTitleText maxWidth={360}>
                  {seminar?.[`title${i18next.language || "En"}`]}
                </SeminarTitleText>
                <Column gap={"30px"} mt={5}>
                  <AlignCenter>
                    <Typography
                      textTransform={"uppercase"}
                      minWidth={190}
                      fontWeight={"bold"}
                      sx={{ whiteSpace: "nowrap" }}
                      fontSize={20}
                    >
                      Date:
                    </Typography>
                    <Typography fontSize={15} sx={{ whiteSpace: "nowrap" }}>
                      {seminar?.dateStart ? new Date(seminar?.dateStart).toLocaleDateString() : null} -{" "}
                      {seminar?.dateEnd ? new Date(seminar?.dateEnd).toLocaleDateString() : null}
                    </Typography>
                  </AlignCenter>
                  <AlignCenter>
                    <Typography
                      textTransform={"uppercase"}
                      minWidth={190}
                      fontWeight={"bold"}
                      sx={{ whiteSpace: "nowrap" }}
                      fontSize={20}
                    >
                      Location:
                    </Typography>
                    <Typography fontSize={15}>
                      {seminar?.[`locationEn`]}
                    </Typography>
                  </AlignCenter>
                  <AlignCenter>
                    <Typography
                      textTransform={"uppercase"}
                      minWidth={190}
                      fontWeight={"bold"}
                      sx={{ whiteSpace: "nowrap" }}
                      fontSize={20}
                    >
                      Entrance fee:
                    </Typography>
                    <Typography fontSize={15} sx={{ whiteSpace: "nowrap" }}>
                      {seminar?.price}
                    </Typography>
                  </AlignCenter>
                </Column>

             
              </Column>
            </Box>
            <Column>
              <Box
              width={`calc(100vw - 624px)`}
              height={isMobile ? "100%" : "636px"}
              
              boxSizing={'border-box'}
              >
                {seminar?.media && <SkiderSeminar media={seminar.media} />}
              </Box>
             
              <Box>
                <Typography mt={"48px"}>
                  {seminar &&
                    <RenderOutput output={seminar['descriptionEn']} />}
                </Typography>
              </Box>
            </Column>
          </Flex>
        </Box>
      </Flex>
    </>
  );
};

export default Seminar;
