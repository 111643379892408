import {
  Divider,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import {
  AlignCenter,
  Column,
  EventCard,
  EventCardAddress,
  EventCardContent,
  EventCardDate,
  EventCardDescription,
  EventCardTitle,
  Flex,
  Q1,
  SectionTitle,
} from "../models/boxes";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getSeminars } from "../axios/Api";
import i18next from "i18next";
import { UpcomingIcon } from "../assets/icons/upcoming";
import { LocationIcon } from "../assets/icons/locationIcon";
import LocationOnIcon from "@mui/icons-material/LocationOn";

export const SeminarsBlock = () => {
  const [seminars, setSeminars] = useState(null);
  const isMobile = useMediaQuery("(max-width:820px)");
  const isTablet = useMediaQuery("(max-width:1200px)");
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const data = await getSeminars();
      setSeminars(data);
    })();
  }, []);
  return (
    <Box mt={"50px"} id={"seminars-block"} position={"relative"} zIndex={999}>
      <Box padding={isMobile ? "0 20px" : "0 80px"} boxSizing={"border-box"}>
        <SectionTitle fontSize={'36px'}>Seminars and Events</SectionTitle>
        {!isTablet ? (
          <Flex gap={"40px"} justifyContent={"space-between"} mt={10}>
            {seminars?.slice(0, 4)?.map((item) => {
              return (
                <EventCard
                  onClick={() => navigate(`seminar/${item.id}`)}
                  key={item.id}
                >
                  {/* {item.isActive&&
                                            <Box position={'absolute'} top={'-2px'} right={'-2px'} zIndex={999} width={200}>
                                                <UpcomingIcon/>
                                            </Box>
                                            } */}
                  <img src={item?.media?.[0]?.media?.url} alt="img" style={{objectFit:'cover !important'}}/>
                  <EventCardContent gap={2}>
                    <Column gap={1}>
                      <EventCardTitle>
                        {item[`subTitle${i18next.language||"En"}`]}
                      </EventCardTitle>
                      <EventCardDescription>
                        {item[`subDescription${i18next.language||"En"}`]?.slice(0,130)}...
                      </EventCardDescription>
                    </Column>
                    <Column gap={0.5}>
                    <EventCardDate active={item.isActive}>
                      {new Date(item.dateStart).toLocaleDateString()}
                    </EventCardDate>
                    <EventCardAddress style={{ fontSize: "1vmax" }}>
                      <LocationIcon />
                      {item[`location${i18next.language||"En"}`]}
                    </EventCardAddress>
                    </Column>
                  </EventCardContent>
                </EventCard>
              );
            })}
          </Flex>
        ) : (
          <Box mt={6}>
            <Swiper
              centeredSlidesBounds={true}
              spaceBetween={30}
              style={{ paddingBottom: '25px' }}
              pagination={{ clickable: true }}
              modules={[Pagination]}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                },
                650:{
                    slidesPerView:2
                },
                1000:{
                    slidesPerView:3
                },
                // 425:{
                //     slidesPerView:1.1
                // },
                // 470:{
                //     slidesPerView:1.3
                // },
                // 570:{
                //     slidesPerView:1.5
                // },
                // 700: {
                //   slidesPerView: 1.8,
                // },
                // 761: {
                //   slidesPerView: 2.2,
                // },
                // 820: {
                //   slidesPerView: 1.9,
                // },
                // 900: {
                //   slidesPerView: 2.1,
                // },
                // 1024: {
                //   slidesPerView: 2,
                // },
                // 1130: {
                //   slidesPerView: 2.2,
                // },
              }}
            >
              {seminars?.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <EventCard onClick={() => navigate(`seminar/${item.id}`)}>
                      {item.isActive && (
                        <Box
                          position={"absolute"}
                          top={"-1.7px"}
                          right={"-2px"}
                        >
                          <UpcomingIcon width={"200px"} />
                        </Box>
                      )}
                      <img src={item?.media?.[0]?.media?.url} alt="img" style={{objectFit:'cover !important'}}/>
                      <EventCardContent gap={3}>
                    <Column gap={2}>
                      <EventCardTitle height={60}>
                        {item[`subTitle${i18next.language||"En"}`]}
                      </EventCardTitle>
                      <EventCardDescription>
                        {item[`subDescription${i18next.language||"En"}`]?.slice(0,130)}...
                      </EventCardDescription>
                    </Column>
                    <Column gap={1}>
                    <EventCardDate active={item.isActive}>
                      {new Date(item.dateStart).toLocaleDateString()}
                    </EventCardDate>
                    <EventCardAddress>
                      <LocationIcon />
                      {item[`location${i18next.language||"En"}`]}
                    </EventCardAddress>
                    </Column>
                  </EventCardContent>
                    </EventCard>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </Box>
        )}

        <AlignCenter mt={2} width={"100%"} justifyContent={"center"}>
          <Q1 onClick={()=>navigate('/seminars')}>
            all seminars
            <Divider
              sx={{
                mt: 1,
                background: "rgba(0, 0, 0, 0.8)",
                border: "1px solid #464646",
              }}
            />
          </Q1>
        </AlignCenter>
      </Box>
    </Box>
  );
};
