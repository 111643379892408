import { Box, useMediaQuery } from "@mui/material";

export const PaddingLayout = ({ children }) => {
  const isMobile = useMediaQuery("(max-width:820px)");
  return (
    <Box p={isMobile ? "0 20px" : "0 80px"} boxSizing={"border-box"}>
      {children}
    </Box>
  );
};
