import { Box } from "@mui/material";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import React, { useState } from "react";

const galleryImages = [];

for (let i = 1; i <= 44; i++) {
  const image = require(`../assets/gallery/${i}-min.png`);
  galleryImages.push({
    src: image,
    title: "img",
    width: 2,
    height: 2,
  });
}

export const ImageGallery = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const renderImage = (props) => {
    return (
      <div
        key={props.index}
        onClick={() => openLightbox(props.index)}
        style={{
          width: props.photo.width,
          height: props.photo.height,
          boxSizing: "border-box",
          cursor: "pointer",
        }}
      >
        <img
          src={props.photo.src}
          alt={props.photo.title}
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
      </div>
    );
  };

  return (
    <Box pt={6}>
      <Gallery photos={galleryImages} renderImage={renderImage} />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={galleryImages.map((x) => ({
                ...x,
                srcset: x.src,
                // caption: x.title
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </Box>
  );
};
