import "./App.css";
import { useEffect, useState } from "react";
import { useRoutes } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import { createTheme, ThemeProvider } from "@mui/material";
import Main from "./pages/main/main";
import { Header } from "./components/Header";
import Seminar from "./pages/seminar/seminar";
import { Sensei } from "./pages/sensei/sensei";
import { Location } from "./pages/location/location";
import Seminars from "./pages/seminars/seminars";

function App() {
  const [loader, setLoader] = useState(true);
  const [current, setCurrent] = useState(1);
  const darkTheme = createTheme({
    typography: {
      fontFamily: [
        "Montserrat",
        "sans-serif",
        "serif",
        "Noto Serif Armenian",
      ].join(","),
    },
  });
  useEffect(() => {
    Aos.init();
    setTimeout(() => {
      setLoader(false);
    }, 5000);
  }, []);

  const routes = useRoutes([
    {
      path: "",
      element: <Header current={current} setCurrent={setCurrent} />,
      children: [
        {
          path: "",
          element: <Main current={current} setCurrent={setCurrent} />,
        },
        {
          path: "seminar/:id",
          element: <Seminar />,
        },
        {
          path: "instructor/:id",
          element: <Sensei />,
        },
        {
          path: "location/:id",
          element: <Location />,
        },
        {
          path:"seminars",
          element:<Seminars/>
        }
      ],
    },
  ]);
  return <ThemeProvider theme={darkTheme}>{routes}</ThemeProvider>;
}

export default App;
