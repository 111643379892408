import { Canvas, useFrame, useLoader } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { Suspense, useEffect, useRef, useState } from "react";
const Model = () => {
  const [scrollY, setScrollY] = useState(0);
  const meshRef = useRef();

  useEffect(() => {
    const onScroll = () => {
      setScrollY(window.pageYOffset);
      // meshRef.current.rotation.y = scrollY * 0.01;
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);
  useFrame((state, delta) => {
    meshRef.current.position.x =
      -(window.innerWidth * 0.02) / 2 + scrollY * 0.02;
    meshRef.current.rotation.y = scrollY * 0.01;
  });

  const gltf = useLoader(GLTFLoader, "/katana_eternity.glb");
  return (
    <>
      <ambientLight />
      <ambientLight />
      <pointLight position={[20, 10, 55]} />
      <primitive
        ref={meshRef}
        position={[0, 0, -2]}
        rotation={[scrollY * 0.001, scrollY * 0.001, scrollY * 0.001]}
        object={gltf.scene}
        scale={2}
      />
    </>
  );
};

export default function Katana() {
  return (
    <Canvas
      style={{
        width: "100%",
        height: "100%",
        position: "fixed",
        background: "none",
        zIndex: 1,
      }}
    >
      <Suspense>
        <Model />
      </Suspense>
    </Canvas>
  );
}
