import { Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import { VideoSection } from "../../components/videoSection";
import {
  AlignCenter,
  EventsBox,
  GradientLeft,
  GradientRight,
  SectionTitle,
} from "../../models/boxes";
import { AboutUs } from "../../components/aboutus";
import { ContactUs } from "../../components/contactUs";
import { Sponsors } from "../../components/slider";
import { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
import { MobileTab } from "../../components/mobileTab";
import Katana from "../../katanaModel";
import { ImageGallery } from "../../components/gallery";
import { useTheme } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { InstructorsIcon } from "../../assets/icons/instructors";
import { CalendarIcon } from "../../assets/icons/calendar";
import { ToriIcon } from "../../assets/icons/tori";
import { GalleryIcon } from "../../assets/icons/gallery";
import { SenseisBlock } from "../../components/SenseisBlock";
import { Locations } from "../../components/locations";
import { SeminarsBlock } from "../../components/seminarsBlock";
import { ScheduleBlock } from "../../components/scheduleBlock";

const Main = ({current, setCurrent}) => {
  const isMobile = useMediaQuery("(max-width:820px)");
  const theme = useTheme();
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  const handleChange = (event, newValue) => {
    setCurrent(newValue);
  };

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }

  const data = [
    {
      icon: <InstructorsIcon color={current === 0 ? "white" : "#333333"} />,
      title: "Instructors",
    },
    {
      icon: <CalendarIcon color={current === 1 ? "white" : "#333333"} />,
      title: "Schedule",
    },
    {
      icon: <GalleryIcon color={current === 2 ? "white" : "#333333"} />,
      title: "Gallery",
    },
  ];

  return (
    <>
      <Katana />
      <VideoSection />
      <AboutUs />

      <Box
        id={"tab-block"}
        sx={{
          background: "#DDDBD6",
          borderRadius: isMobile ? "70px" : "80px",
          position: "relative",
          zIndex: 20,
        }}
        p={isMobile ? "20px 0" : "70px 0"}
        mt={"100px"}
      >
        <Box
          boxSizing={"border-box"}
          p={isMobile ? "0 20px" : "0 80px"}
          width={"calc(100% - 5px)"}
        >
          <EventsBox>
            {/* {isMobile ? (
              <MobileTab current={value} setCurrent={setValue} />
            ) :  */}
            
              <AlignCenter
                justifyContent={"space-between"}
                p={isMobile?"6px":"10px"}
                sx={{ background: "white" }}
                borderRadius={"100px"}
                // width={'100%'}
              >
                <Tabs
                  value={current}
                  onChange={handleChange}
                  indicatorColor="secondary"
                  textColor="inherit"
                  // variant="fullWidth"
                  aria-label="full width tabs example"
               
                  TabIndicatorProps={{
                    sx: {
                      backgroundColor: "rgba(0, 0, 0, 0.8)",
                      height: "100%",
                      borderRadius: "99px",
                    },
                  }}
                >
                  {data.map((item, index) => {
                    return (
                      <Tab
                        key={index}
                        sx={{
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          minHeight: "35px",
                          width:'250px',
                          "@media (max-width:820px)":{
                            width:'calc(100%/3)'
                          },
                          // "@media (min-width:820px)":{
                          //   width:'250px'
                          // },
                          ":hover":
                            index === current
                              ? {}
                              : {
                                  background: "#D7D7D7",
                                  opacity: "0.5",
                                  transition: "0.15s ease-in",
                                  borderRadius: "99px",
                                },
                        }}
                        icon={<Box className="tab-svg-icons">{item.icon}</Box>}
                        iconPosition="start"
                        label={
                          isMobile?<></>:<Typography
                            sx={{
                              fontFamily: "Montserrat",
                              fontWeight: 600,
                              fontSize: "16px",
                            }}
                          >
                            {item.title}
                          </Typography>
                        }
                        {...a11yProps(index)}
                      />
                    );
                  })}
                </Tabs>
              </AlignCenter>
            
            <>
              <TabPanel value={current} index={0} dir={theme.direction}>
                <SenseisBlock />
              </TabPanel>
              <TabPanel value={current} index={1} dir={theme.direction}>
                <ScheduleBlock />
              </TabPanel>
              <TabPanel value={current} index={2} dir={theme.direction}>
                <ImageGallery />
              </TabPanel>
            </>
          </EventsBox>
        </Box>
      </Box>
      {!isMobile&&<Box mt={"20px"}>
        <Box
          className={"running-line"}
          width={"100%"}
          whiteSpace={"nowrap"}
          sx={{ overflow: "hidden !important" }}
        >
          <h1 className="marquee">
            <span>
              There are no contests in the Art of Peace • There are no contests
              in the Art of Peace • There are no contests in the Art of Peace •
              There are no contests in the Art of Peace • There are no contests
              in the Art of Peace • There are no contests in the Art of Peace •
              There are no contests in the Art of Peace • There are no contests
              in the Art of Peace • There are no contests in the Art of Peace •
              There are no contests in the Art of Peace • There are no contests
              in the Art of Peace • There are no contests in the Art of Peace •
              There are no contests in the Art of Peace • There are no contests
              in the Art of Peace • There are no contests in the Art of Peace •
              There are no contests in the Art of Peace • There are no contests
              in the Art of Peace • There are no contests in the Art of Peace •
              There are no contests in the Art of Peace • There are no contests
              in the Art of Peace • There are no contests in the Art of Peace •
              There are no contests in the Art of Peace • There are no contests
              in the Art of Peace • There are no contests in the Art of Peace •
              There are no contests in the Art of Peace • There are no contests
              in the Art of Peace • There are no contests in the Art of Peace •
              There are no contests in the Art of Peace • There are no contests
              in the Art of Peace • There are no contests in the Art of Peace •
              There are no contests in the Art of Peace • There are no contests
              in the Art of Peace • There are no contests in the Art of Peace •
              There are no contests in the Art of Peace • There are no contests
              in the Art of Peace • There are no contests in the Art of Peace •
              There are no contests in the Art of Peace • There are no contests
              in the Art of Peace • There are no contests in the Art of Peace •
              There are no contests in the Art of Peace • There are no contests
              in the Art of Peace • There are no contests in the Art of Peace •
              There are no contests in the Art of Peace • There are no contests
              in the Art of Peace • There are no contests in the Art of Peace •
              There are no contests in the Art of Peace • There are no contests
              in the Art of Peace • There are no contests in the Art of Peace •{" "}
            </span>
          </h1>
        </Box>
      </Box>}
      {/* <AlignCenter width={'100%'} justifyContent={'center'}> */}
      <SeminarsBlock />
      {/* </AlignCenter> */}

      <AlignCenter
        justifyContent={"center"}
        width={"100%"}
        id={"contact-us"}
        p={isMobile ? "0 20px" : "0 80px"}
        boxSizing={"border-box"}
      >
        <ContactUs />
      </AlignCenter>
      <Box height={210} mt={15}>
        <AlignCenter width={"100%"}>
          <Box
            // width={isMobile?'calc(100% - 30px)':'calc(100vw - 250px)'}
            p={isMobile ? "0 20px" : "0 80px"}
            mb={4}
          >
            <SectionTitle fontSize={"36px"}>Sponsors</SectionTitle>
          </Box>
        </AlignCenter>
        <Box position={"relative"}>
          <GradientLeft />
          <Sponsors />
          <GradientRight />
        </Box>
      </Box>
    </>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
      
    >
      {value === index && (
        <Box>{children}</Box>
      )}
    </Box>
  );
}

export default Main;
