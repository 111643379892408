import { Grid, IconButton, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import { Column, SpaceBetween, TrainerCard } from "../models/boxes";
import rightArrow from "../assets/icons/rightArrow.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getSenseys } from "../axios/Api";
import i18next from "i18next";

export const SenseisBlock = () => {
  const [senseys, setSenseys] = useState(null);
  const isMobile = useMediaQuery("(max-width:820px)");
  const isTablet = useMediaQuery("(max-width:1200px)");
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const data = await getSenseys();
      setSenseys(data);
    })();
  }, []);
  return (
    <>
      {!isTablet ? (
        <Grid container spacing={"40px"} mt={'10px'}>
          {senseys?.map((item, index) => {
            return (
              <Grid item xs={3} key={item.id}>
                <TrainerCard onClick={() => navigate(`/instructor/${item.id}`)}>
                  <img
                    src={item.url}
                    style={{
                      objectFit: "cover",
                      objectPosition: "top",
                      borderTopLeftRadius: 30,
                      borderTopRightRadius: 30,
                    }}
                    alt=""
                  />
                  <SpaceBetween alignItems={"flex-start"} p={"13px 0 15px 0"}>
                    <Typography
                      pl={"20px"}
                      maxWidth={113}
                      fontFamily="Noto Serif Armenian"
                      fontWeight={600}
                      fontSize="2vw"
                      // lineHeight={"35px"}
                      sx={{
                        "@media (min-width:1700px)": {
                          lineHeight: '46px'
                        },
                        "@media (max-width:1700px)": {
                          lineHeight: '35px'
                        }
                      }}
                      letterSpacing={"-0.04em"}
                      variant="h5"
                      color={"rgba(0, 0, 0, 0.8)"}
                    >
                      {item[`name${i18next.language || "En"}`]}
                    </Typography>
                    <Column pr={"20px"} alignItems={"flex-end"}>
                      <Typography
                        maxWidth={index === 0 ? 80 : 130}
                        fontFamily="Montserrat"
                        fontWeight={500}
                        fontSize="0.9vw"
                        mt={0.8}
                        textAlign={"end"}
                        color={"rgba(0, 0, 0, 0.5)"}
                        sx={{ lineBreak: "anywhere" }}
                      >
                        {item[`position${i18next.language || "En"}`]}
                      </Typography>
                      <button
                        style={{
                          border: "none",
                          marginTop: 10,
                          background: "none",
                          cursor: "pointer",
                          padding: 0,
                        }}
                      >
                        <img src={rightArrow} alt="arrow" />
                      </button>
                    </Column>
                  </SpaceBetween>
                </TrainerCard>
              </Grid>
            );
          })}
        </Grid>
      ) : (
        <Box mt={isMobile ? '40px' : 6} sx={{ boxSizing: 'border-box' }}>
          <Swiper
            style={{ paddingBottom: '35px' }}
            centeredSlidesBounds={true}
            spaceBetween={30}
            breakpoints={{
              320: {
                slidesPerView: 1,
              },
              650: {
                slidesPerView: 2
              },
              1000: {
                slidesPerView: 3
              },
              // 570:{
              //     slidesPerView:1.5
              // },
              // 700: {
              //   slidesPerView: 1.8,
              // },
              // 761: {
              //   slidesPerView: 2.2,
              // },
              // 820: {
              //   slidesPerView: 2.1,
              //   spaceBetween:20
              // },
              // 900: {
              //   slidesPerView: 2,
              //   spaceBetween:20
              // },
              // 1024: {
              //   slidesPerView: 2,
              // },
              // 1130: {
              //   slidesPerView: 2.2,
              // },
            }}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
          >
            {senseys?.map((item, index) => {
              return (
                <SwiperSlide
                  onClick={() => navigate(`/instructor/${item.id}`)}
                  key={item.id}
                >
                  <TrainerCard>
                    <img
                      src={item.url}
                      style={{
                        objectFit: "cover",
                        borderTopLeftRadius: 30,
                        borderTopRightRadius: 30,
                        height: 450,
                      }}
                      alt=""
                    />
                    <SpaceBetween alignItems={"center"} p={"20px 0"}>
                      <Typography
                        pl={"30px"}
                        maxWidth={113}
                        fontFamily="'Noto Serif Armenian'"
                        fontWeight={400}
                        fontSize="40px"
                        lineHeight={"45px"}
                        letterSpacing={"-0.04em"}
                        variant="h5"
                        color={"rgba(0, 0, 0, 0.8)"}
                      >
                        {item[`name${i18next.language || "En"}`]}
                      </Typography>
                      <Column pr={"10px"}>
                        <Typography
                          maxWidth={index === 0 ? 80 : 130}
                          fontFamily="'Noto Serif Armenian'"
                          fontWeight={400}
                          fontSize="18px"
                          lineHeight={"130%"}
                          letterSpacing={"-0.02em"}
                          color={"rgba(0, 0, 0, 0.2)"}
                          sx={{ lineBreak: "anywhere" }}
                        >
                          {item[`position${i18next.language || "En"}`]}
                        </Typography>
                        <IconButton>
                          <img src={rightArrow} alt="arrow" />
                        </IconButton>
                      </Column>
                    </SpaceBetween>
                  </TrainerCard>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </Box>
      )}
    </>
  );
};
