import axios from "axios";

const Api = axios.create({
  baseURL: "https://admin.aikikai.am/api/",
});

export const AboutData = async () => {
  const { data } = await Api.get("about/6443b44181954135e2d70759");
  return data;
};

export const getSenseys = async () => {
  const {
    data: { docs },
  } = await Api.get("senseis");
  return docs;
};

export const getSensei = async (id) => {
  const { data } = await Api.get(`senseis/${id}`);
  return data;
};

export const getSeminars = async () => {
  const {
    data: { docs },
  } = await Api.get("seminars");
  return docs;
};

export const getSeminar = async (id) => {
  const { data } = await Api.get(`seminars/${id}`);
  return data;
};
export const getLocations = async () => {
  const {
    data: { docs },
  } = await Api.get(`locations`);
  return docs;
};

export const getlocation = async (id) => {
  const { data } = await Api.get(`locations/${id}`);
  return data;
};

export const getMedia = async (id) => {
  const { data } = await Api.get(`media/${id}`);
  return data;
};

export const getSchedules = async () => {
  const {
    data: { docs },
  } = await Api.get("schedule");
  return docs;
};

export const getReports = async () => {
  const {
    data: { docs },
  } = await Api.get("reports");
  return docs;
};
